import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/index.less'
import BootstrapVue from 'bootstrap-vue'
import './styles/css/about.css'
import './styles/css/base.css'
// import './styles/css/01.css'
import './styles/css/cases.css'
import './styles/css/index.css'
import './styles/css/media.css'
import './styles/css/media.css'
import './styles/css/news.css'
import './styles/css/solution.css'




// import $ from 'jquery'
// window.jQuery = $
// window.$ = $
// import 'jquery/dist/jquery.min'
Vue.use(BootstrapVue)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/js/bootstrap'
import './styles/css/product/broadcast.css'
import './styles/css/product/contentProduction.css'
import './styles/css/product/encoder.css'
import './styles/css/product/streamMedia.css'

Vue.use(ElementUI)
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
