import Vue from 'vue'
import VueRouter from 'vue-router'
const Layout = () => import('@/views/layout.vue')
const Index = () => import('@/views/home/index.vue')
const Empty = () => import('@/views/empty.vue')
const Media = () => import('@/views/media/index.vue')
const News01 = () => import('@/views/news/news_01.vue')
const News02 = () => import('@/views/news/news_02.vue')
const Cases = () => import('@/views/cases.vue')
const About = () => import('@/views/about.vue')
const Encoder = () => import('@/views/production/encoder.vue')
const Broadcast = () => import('@/views/production/broadcast.vue')
const ContentProduction = () => import('@/views/production/contentProduction.vue')
const Live = () => import('@/views/production/live.vue')
const NetworkTesting = () => import('@/views/production/NetworkTesting.vue')
const SandBox = () => import('@/views/production/sandBox.vue')
const Screen = () => import('@/views/production/screen.vue')
const StreamMedia = () => import('@/views/production/streamMedia.vue')
const VirtualStudio = () => import('@/views/production/virtualStudio.vue')
const Privacy = () => import('@/views/privacy/index.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    // 布局骨架
    component: Layout,
    // 二级路由
    children: [
      {
        path: '/',
        name: 'index',
        // 首页
        component: Index
      },
      {
        path: '/media',
        name: 'media',
        // 公司动态
        component: Media
      },
      {
        path: 'news/01',
        name: 'news/01',
        //  新闻中心1
        component: News01
      },
      {
        path: 'news/02',
        name: 'news/02',
        // 新闻中心2
        component: News02
      },
      {
        path: '/cases',
        name: 'cases',
        // 行业客户
        component: Cases
      },
      {
        path: '/about',
        name: 'about',
        // 关于我们
        component: About
      },
      // 产品中心
      {
        path: '/encoder',
        name: 'encoder',
        // 编转码
        component: Encoder
      },
      {
        path: '/broadcast',
        name: 'broadcast',
        // 编转码
        component: Broadcast
      },
      {
        path: '/contentProduction',
        name: 'contentProduction',
        component: ContentProduction
      },
      {
        path: '/live',
        name: 'live',
        component: Live
      },
      {
        path: '/networkTesting',
        name: 'networkTesting',
        component: NetworkTesting
      },
      {
        path: '/sandBox',
        name: 'sandBox',
        component: SandBox
      },
      {
        path: '/screen',
        name: 'screen',
        component: Screen
      },
      {
        path: '/streamMedia',
        name: 'streamMedia',
        component: StreamMedia
      },
      {
        path: '/virtualStudio',
        name: 'virtualStudio',
        component: VirtualStudio
      },
      {
        path:'/privacy',
        name: 'privacy',
        component: Privacy
      }
    ]
  },

  {
    path: '/empty',
    name: 'empty',
    // 首页
    component: Empty
  }
]
const router = new VueRouter({
  // mode: 'history',
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router
